#tickets {
  justify-content: space-between;
  text-align: center;
  align-items: center;

  &.show {
    a {
      pointer-events: all;
    }
  }

  .top_wrapper {
    flex-grow: 10;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;

    b {
      // display: block;
    }

    p {
      line-height: 1.5em;
    }

    a {
      background: transparent;
    }
  }

  h1 {
    // font-size: 3em;
    // margin-bottom: 0;
  }

  h1,
  p {
    letter-spacing: 0.15em;
    position: relative;
  }

  .theaters {
    // margin-top: -4em;
    margin: 0;
    padding-bottom: 2em;
  }
}