html body .e-widget-preloader, html body .e-widget-wrapper {
  box-shadow: none !important;
}

.contest {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 100%;
  font-weight: 300;

  .e-widget-wrapper {
    height: 100%;

    iframe {
      height: 100% !important;
    }
  }

  .e-widget-preloader,
  iframe.e-embed-frame {
    background: transparent;
    margin: 0;
  }

  &.show {
    a {
      pointer-events: all;
    }

    iframe {
      pointer-events: all;
    }
  }

  .big {
    font-size: 2.5em;
    display: block;
    line-height: 1.5em;
  }

  .top_wrapper {
    flex-grow: 10;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    max-width: 85%;
    margin-top: 1em;

    a {
      font-size: 2.5em;
      margin-top: 1em;
      background: $purple;
      color: $white;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: 2.5em;
      width: 90%;
    }

    p {
      line-height: 1.5em;
    }
  }

  h1 {
    // font-size: 2em;
    margin-bottom: 0em;
    margin-top: 3.5em;

    font-size: 3em;
    line-height: 1.25em;
    font-weight: 300;
  }

  h1,
  p {
    letter-spacing: 0.15em;
    position: relative;
  }

  .get_tickets_button {
    font-size: 1.75em;
    margin: 0 auto 2em;
    color: $white;
  }
}