#dossier,
#bagel,
#cloud {
  ul li,
  h1 {
    opacity: 0;
  }

  &.show {

    ul li,
    h1 {
      animation-name: flickerin;
      animation-duration: 0.2s;
      animation-delay: 0.4s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    h1 {
      animation-duration: 0.25s;
      animation-delay: 0.45s;
    }

    ul li {
      &:nth-child(1) {
        animation-duration: 0.2s;
        animation-delay: 0.45s;
      }

      &:nth-child(2) {
        animation-duration: 1s;
        animation-delay: 0.35s;
      }

      &:nth-child(3) {
        animation-duration: 0.3s;
        animation-delay: 0.4s;
      }

      &:nth-child(4) {
        animation-duration: 0.15s;
        animation-delay: 0.4s;
      }

      &:nth-child(5) {
        animation-duration: 0.4s;
        animation-delay: 0.4s;
      }

      &:nth-child(6) {
        animation-duration: 0.25s;
        animation-delay: 0.4s;
      }
    }
  }

  &.hide {

    ul li,
    h1 {
      animation-name: flickerout;
      animation-duration: 0.2s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }

    ul li {
      &:nth-child(1) {
        animation-duration: 0.2s;
        animation-delay: 0.15s;
      }

      &:nth-child(2) {
        animation-duration: 0.4s;
        animation-delay: 0s;
      }

      &:nth-child(3) {
        animation-duration: 0.25s;
        animation-delay: 0.075s;
      }

      &:nth-child(4) {
        animation-duration: 0.25s;
        animation-delay: 0.05s;
      }

      &:nth-child(5) {
        animation-duration: 0.75s;
        animation-delay: 0s;
      }

      &:nth-child(6) {
        animation-duration: 0.25s;
        animation-delay: 0.05s;
      }
    }
  }

  a {
    pointer-events: none;
  }

  &.show:not(.hide) {
    .files,
    a {
      pointer-events: all;
    }
  }

  // h1 {
  //   font-size: 2.2em;
  //   line-height: 1em;
  //   margin: 0 0 1em;
  // }

  .files {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    
    pointer-events: none;

    li {
      flex-basis: 50%;
      padding: 0.5em;
      position: relative;

      &.nothumb {
        p {
          color: #c00000;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      p {
        text-align: center;
        margin: 0.25em 0 1em;
        font-size: 1.4em;
        line-height: 1.5em;
      }
    }
  }

  #youtube_modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;

    &.show {
      opacity: 1;
      pointer-events: all;

      .vid_wrapper {
        iframe {
          display: block;
          pointer-events: all;
        }
      }
    }

    .vid_wrapper {
      position: relative;
      width: 90%;

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
      }

      iframe {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#dossier {
  z-index: 2;

  &.show:not(.hide) {
    .dossier_wrapper {
      pointer-events: all;
    }
  }

  h1 {
    margin-top: 5em;
  }

  .files {
    padding-bottom: 2em;
  }

  .dossier_wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;
    flex-grow: 100;
    overflow: auto;
  }
}
