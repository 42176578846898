#tmp_moon {
  opacity: 0;
  position: absolute;
  left: 50%;
  transition: opacity 0.5s;

  &.show {
    // opacity: 1;
  }
}

#ar_landing {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // background: rgba($black, 0.75);
  z-index: 1;

  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;

  .tt {
    position: relative;
    max-width: 95%;
    margin: 3em auto 1.5em;
  }

  .rings {
    opacity: 0.5;
    margin-bottom: -2em;
  }

  .bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1, 1);
    display: none;
    // &::before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba($black, 0.65);
    //   transform: scale(1, 1);
    // }
  }

  &.show {
      pointer-events: all;
      opacity: 1;

      &.hide {
          pointer-events: none;

          #tmp_moon {
            opacity: 0;
          }
      }
  }

  &.hide {
    opacity: unset;
    transition: visibility 0s;
    transition-delay: 0.75s;
    visibility: hidden;

    .bg {
      transform: scale(0.015, 1);
      transition: transform 0.25s ease-out;
      transition-delay: 0.5s;
  
      &::before {
        transform: scale(1, 0.015);
        transition: transform 0.25s ease-in;
        transition-delay: 0.3s;
      }
    }

    h1,
    #version,
    a {
      animation-name: flickerout;
      animation-duration: 0.2s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }
  }

  h1 {
      font-size: 1.3rem;
      font-weight: 300;
      margin: 0 0 3em;
      padding-bottom: 3em;
      text-align: center;
      position: relative;
      line-height: 1.45em;
      letter-spacing: .25em;

      // &::before {
      //   content: attr(data-version);
      //   color: $purple;
      //   font-size: 1.2rem;
      //   font-weight: $bold;
      //   line-height: 1em;
      //   position: absolute;
      //   top: calc(50% - 0.9rem);
      //   letter-spacing: 0.1em;
      //   left: 0;
      //   width: 100%;
      //   text-align: center;
      // }

      &::after {
        content: "...";
        font-weight: $bold;
        font-size: 2rem;
        display: block;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        line-height: 1em;
        color: $purple;
      }
  }

  #version {
      font-size: 1.849em;
      color: $purple;
      margin: 0;
      position: relative;
  }

  a {
      font-size: 1.8em;
      line-height: 2em;
      font-weight: $bold;
      color: $purple;
      position: relative;
      letter-spacing: 0.1em;

      &::before {
          content: "[";
          margin-right: 0.5em;
      }

      &::after {
          content: "]";
          margin-left: 0.5em;
      }
  }
}