#debris {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  h1 {
    color: $purple;
    font-size: 1.1em;
    max-width: 75%;
    position: absolute;
    top: 15%;
  }

  p {
    font-size: 1.6em;
    line-height: 1.25em;
  }

  // h1,
  // p {
  //   max-width: 75%;
  // }
}