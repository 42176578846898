#size_comparison {
  .info_wrapper {
    a {
      &.external_link {
        color: $purple;
        text-decoration: underline;
        pointer-events: all;
      }
    }
  }

  #moon_label,
  #sun_label {
    position: absolute;
    font-size: 1.1em;
    background: rgba($black, 0.5);
    border-top-width: 5px;
    margin: 0;
    opacity: 0;

    i {
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 100%;
      border-bottom: solid 2px $purple;
      height: 0px;
      font-style: normal;

      b {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        border-right: solid 2px $purple;
        font-weight: $normal;

        span {
          display: block;
          position: absolute;
          font-size: 2em;
          top: 50%;
          left: calc(100% + 0.5em);
          transform: translateY(-50%);
        }
      }
    }
  }

  &.show {
    #moon_label,
    #sun_label {
      pointer-events: all;
      animation-name: flickerin;
      animation-duration: 0.25s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  &.hide {
    #moon_label,
    #sun_label {
      animation-name: flickerout;
      animation-duration: 0.25s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }
  }
}