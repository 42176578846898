/* autoprefixer grid: on */
//
@import './includes/variables';

html {
    font-size: 10px;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    -ms-content-zooming: none; /* Disables zooming */
    color: $white;
    font-family: $regular_font;
    font-weight: $normal;
    font-size: min(#{linearSizeWidth(8, 10, 320, 428)}, #{linearSizeHeight(6, 10, 320, 428)});
    -webkit-marquee-increment: 1vw; // Safari bug fix https://www.sarasoueidan.com/blog/safari-fluid-typography-bug-fix/
    line-height: 1.25em;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background: $black;
    top: 0;
    left: 0;
    width: 100%;

    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
    
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    touch-action: pan-x pan-y;

    .cssloaded & {
        opacity: 1;
        transition: opacity 0.75s ease-in-out 0.25s;
    }

    &[data-section="Desktop"] {
        overflow: hidden;

        #camerafeed {
            opacity: 0;

            &.show {
                opacity: 1;
                transition: opacity 1s;
            }
        }
    }
}

/* Screen Reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}

a {
    color: $white;
    -webkit-user-drag: none;
    text-decoration: none;
}

p {
    font-size: 1.6em;
    line-height: 1.25em;
}

a:focus,
a:active,
button:focus,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
    line-height: 1em;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
    line-height: 1em;
    font-weight: $normal;
}

#shell {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#back_to_site {
    opacity: 0;

    &.show {
        z-index: 100;
        pointer-events: all;
        animation-name: flickerin;
        animation-duration: 0.25s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.hide {
        animation-name: flickerout;
        animation-duration: 0.25s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: reverse;
        animation-fill-mode: forwards;
    }
}

.border_button,
.x_button {
    font-size: 1.2em;
    letter-spacing: 0.1em;
    display: inline-flex;
    align-items: center;
    height: 2.45rem;
    position: absolute;
    top: 2rem;
}

.border_button {
    padding: 0 2em;
    left: 2rem;
    color: $black;
    background: $white;
    // pointer-events: all;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: calc(100% + 3px);
        width: 2px;
        height: calc(50% - 3px);
        background: $white;
    }

    &::before {
        top: 0px;
    }

    &::after {
        bottom: 0px;
    }
}

.x_button {
    right: 2rem;

    .x {
        display: inline-block;
        width: 2.45rem;
        height: 2.45rem;
        border: solid 1px rgba($purple, 0.5);
        margin-left: 1em;
        position: relative;

        &::before,
        &::after {
            content: "";
            display: block;
            height: 1px;
            border-left: solid 0.65rem $white;
            border-right: solid 0.65rem $white;
            width: 3px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: 50%;
            transform: translate(-50%, -50%) rotate(45deg);// translate(-50%, -50%);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}

// #moon_arrow {
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     width: 0px;
//     height: 0px;
//     opacity: 0;

//     &.show {
//         animation-name: flickerin;
//         animation-duration: 0.25s;
//         animation-delay: 0s;
//         animation-iteration-count: 1;
//         animation-fill-mode: forwards;
//     }

//     &.hide {
//         animation-name: flickerout;
//         animation-duration: 0.25s;
//         animation-delay: 0s;
//         animation-iteration-count: 1;
//         animation-direction: reverse;
//         animation-fill-mode: forwards;
//     }

//     span {
//         display: block;
//         position: absolute;
//         transform: translate(-50%, -50%);
//         color: $white;
//         font-size: 8em;
//     }
// }

#ar {      
    footer {
        position: fixed;
        bottom: 0;
        right: 0;

        p {
            font-size: 1em;
            margin: 0 1em;
            line-height: 2.75em;
            color: #666;
        }
    }
}

.section {
    &#dossier,
    &#tickets,
    &.coming_soon {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    opacity: 0;
    // transition: opacity 0.5s;

    * {
        pointer-events: none;
    }

    h1 {
        font-size: 2.2em;
        line-height: 1em;
        margin: 0 0 1em;
    }

    .bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0.015, 1);

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.5);
            transform: scale(1, 0.015);
        }
    }

    &.show {
        opacity: 1;

        .bg {
            transform: scale(1, 1);
            transition: transform 0.25s ease-in;
            transition-delay: 0s;
        
            &::before {
              transform: scale(1, 1);
              transition: transform 0.25s ease-out;
              transition-delay: 0.2s;
            }
        }
    }

    &.hide {
        opacity: unset;
        transition: visibility 0s;
        transition-delay: 0.75s;
        visibility: hidden;

        iframe {
            visibility: hidden !important;
        }

        .bg {
            transform: scale(0.015, 1);
            transition: transform 0.25s ease-out;
            transition-delay: 0.5s;
        
            &::before {
                transform: scale(1, 0.015);
                transition: transform 0.25s ease-in;
                transition-delay: 0.3s;
            }
        }
    }

    .info_wrapper {
        position: absolute;
        bottom: 10%;
        width: 100%;
        left: 0;
        text-align: center;
        opacity: 0;

        * {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        span {
            line-height: 1.5em;
            background: black;
            padding: 2px 4px;
        }
      }
    
      &.show {
        .info_wrapper {
            animation-name: flickerin;
            animation-duration: 0.75s;
            animation-delay: 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
      }
    
      &.hide {
          opacity: unset;
          transition: visibility 0s;
          transition-delay: 0.5s;
          visibility: hidden;

          .instructions {
              span {
                  transform: translateY(-100%);
              }
          }
    
          .info_wrapper {
              animation-name: flickerout;
              animation-duration: 0.25s;
              animation-delay: 0s;
              animation-iteration-count: 1;
              animation-direction: reverse;
              animation-fill-mode: forwards;
          }
      }
}

.fullwh {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#ar_nav,
footer {
    z-index: 3;
}

#ar_zoomed {
    .hotspot {
        .inner {
            opacity: 0;
        }
    }

    &.show {
        .hotspot {
            .inner {
                pointer-events: all;
                animation-name: flickerin;
                animation-duration: 0.5s;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }

            &:nth-child(2) {
                animation-delay: 0.05s;
                animation-duration: 0.65s;
                
            }

            &:nth-child(3) {
                animation-delay: 0.1s;
                animation-duration: 0.25s;
            }
        }
      }
    
      &.hide {
          opacity: unset;
          transition: visibility 0s;
          transition-delay: 0.25s;
          visibility: hidden;
    
          .hotspot {
              .inner {
                animation-name: flickerout;
                animation-duration: 0.35s;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: reverse;
                animation-fill-mode: forwards;
              }

              &:nth-child(2) {
                animation-delay: 0.25s;
                animation-duration: 0.5s;
            }

            &:nth-child(3) {
                animation-delay: 0.1s;
                animation-duration: 0.25s;
            }
          }
      }
}

#ar_find_moon,
#ar_place_moon,
#ar_calibrating {
    display: flex;
    flex-flow: column;
    align-items: center;

    .instructions {
        text-align: center;
        position: absolute;
        max-width: 60%;
        letter-spacing: 0.3em;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        overflow: hidden;

        span {
            display: block;
            transform: translateY(100%);
            transition: transform 0.5s $ease_in_out;
        }
    }

    &.show {
        &.found {
            .instructions {
                &.point {
                    span {
                        transform: translateY(-100%);
                    }
                }
        
                &.found {
                    span {
                        transform: translateY(0%);
                    }
                }
            }

            &.locked {
                .instructions {
                    &.found {
                        span {
                            transform: translateY(-100%);
                        }
                    }
                }
            }
        }

        .instructions {
            &:first-child {
                span {
                    transform: translateY(0%);
                }
            }
        }
    }

    &.hide {
        opacity: unset;
        transition: visibility 0s;
        transition-delay: 0.25s;
        visibility: hidden;

        .instructions {
            span {
                transform: translateY(-100%) !important;
            }
        }
    }
}

#ar_calibrating {
    .instructions {
        top: 75%;
        max-width: 90%;

        p {
            margin-top: 0;
        }

        span {
            &::before {
                content: '[';
                margin-right: 0.75em;
            }

            &::after {
                content: ']';
                margin-left: 0.75em;
            }
        }

        .calibration_progress {
            display: block;
            width: 100%;
            height: 0.65em;
            background: #666;
            margin: 3em auto 0;
            position: relative;

            div {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $purple;
                transform: scaleX(0);
                transform-origin: 0 0;
            }
        }
    }
}

#ar_find_moon {
    #moon_target {
        --target-size: 10em;

        position: absolute;
        top: 0px;
        left: 0px;
        // top: 50%;
        // left: 50%;
        transform: translate3d(-50%, -50%, 1px);

        .target_box {
            // width: 100%;
            // height: 100%;
            margin: 0 auto;

            div {
                // transition: transform 0.25s ease-in-out;
            }

            .ul {
                // transform: translate3d(-200%, -200%, 1px);
            }
        
            .ur {
                // transform: translate3d(200%, -200%, 1px);
            }
        
            .bl {
                // transform: translate3d(-200%, 200%, 1px);
            }
        
            .br {
                // transform: translate3d(200%, 200%, 1px);
            }
        }

        &.found {
            .target_box {
                transform: scale(0.75);

                .inner {
                    opacity: 1;
                    // transform: rotate(125deg) scale(1.15);
                    transition-delay: 0.25s;
                    animation-name: rotate_target;
                    animation-duration: 1s;
                    animation-delay: 0.25s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

.target_box {

    &.test {
        --target-size: 5em;
    }

    width: var(--target-size);
    height: var(--target-size);
    // transition: width 0.5s $ease_out, height 0.5s $ease_out;
    position: relative;

    transform: scale(1);
    transition: transform 0.75s $ease_in_out;

    &>div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &.inner {
            width: 75%;
            height: 75%;
            top: 12.5%;
            left: 12.5%;
            opacity: 0;
            transform: rotate(45deg) scale(1);
            transition: opacity 0.25s;

            &>div {
                &::after {
                    width: calc(var(--target-size) * 0.75);
                    height: calc(var(--target-size) * 0.75);
                    border-width: 2px;
                }
            }
        }

        &>div {
            position: absolute;
            width: 45%;
            height: 45%;
            overflow: hidden;
            // transition: width 0.5s $ease_out, height 0.5s $ease_out;
    
            &::after {
                content: "";
                display: block;
                width: var(--target-size);
                height: var(--target-size);
                position: absolute;
                border: solid 1px $purple;
                border-radius: 100%;
                // transition: width 0.5s $ease_out, height 0.5s $ease_out;
            }

            
    
            &.ul {
                top: 0;
                left: 0;
    
                &::after {
                    top: 0;
                    left: 0;
                }
            }
        
            &.ur {
                top: 0;
                right: 0;
    
                &::after {
                    top: 0;
                    right: 0;
                }
            }
        
            &.bl {
                bottom: 0;
                left: 0;
    
                &::after {
                    bottom: 0;
                    left: 0;
                }
            }
        
            &.br {
                bottom: 0;
                right: 0;
    
                &::after {
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
}

// .target_box {
//     width: 5em;
//     height: 5em;
//     position: relative;

//     div {
//         position: absolute;
//         width: 1.5em;
//         height: 1.5em;
//     }

//     .ul {
//         top: 0;
//         left: 0;
//         border-top: solid 2px $purple;
//         border-left: solid 2px $purple;
//     }

//     .ur {
//         top: 0;
//         right: 0;
//         border-top: solid 2px $purple;
//         border-right: solid 2px $purple;
//     }

//     .bl {
//         bottom: 0;
//         left: 0;
//         border-bottom: solid 2px $purple;
//         border-left: solid 2px $purple;
//     }

//     .br {
//         bottom: 0;
//         right: 0;
//         border-bottom: solid 2px $purple;
//         border-right: solid 2px $purple;
//     }
// }

.hotspot {
    position: absolute;
    display: block;
    width: 0;
    height: 0;

    &.visited {
        opacity: 0.75;

        .inner {
            border-color: #666;
            // background: rgba($black, 0.25);

            &::after {
                // background: #666;
            }
        }
    }
    
    .inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 6em;
        height: 6em;
        border: solid 2px $purple;
        // background: rgba($black, 0.75);
        left: 0px;
        top: 0px;
        transform: translate(-50%, -50%);

        &::after {
            content: "+";
            display: block;
            width: 70%;
            height: 70%;
            // background: $purple;
            color: $white;
            position: absolute;
            top: 15%;
            left: 15%;
            font-size: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.image_sizer {
    display: block;
    width: 100%;
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
        background: #3f3f3f;
    }
}

.dg.ac {
    z-index: 100;
}

.get_tickets_button {
    font-size: 2.5em;
    letter-spacing: 0.15em;
    border: solid 2px $purple;
    line-height: 2em;
    padding: 0 2em;
    margin-top: 1em;
    z-index: 1;
}

@import './nav';
@import './landing';
@import './rings';
@import './content_drops/dossier';
@import './content_drops/bagel';
@import './content_drops/debris';
@import './content_drops/tickets';
@import './content_drops/size_comparison';
@import './8thwall/8thwall';

// @import './ar/8thwall/8thwall_overrides';
// @import './ar/8thwall/almost-there-module';
// @import './ar/8thwall/loading-module';
// @import './ar/8thwall/runtime-error-module';

@-ms-viewport {
    width: device-width;
}

@keyframes flickerin {
    0%, 5% {
        opacity: 0;
    }

    15%, 20%, 40%, 42% {
        opacity: 0.2;
    }

    48%, 57%, 65%, 75%, 77% {
        opacity: 0.5;
    }

    80%, 85%, 90%, 95% {
        opacity: 0.75;
    }

    3%, 7%, 19%, 30% {
        opacity: 0.35;
    }

    41%, 44%, 52%, 60% {
        opacity: 0.5;
    }

    70%, 76%, 78%, 84% {
        opacity: 0.65;
    }

    88%, 93%, 100% {
        opacity: 1;
    }
}

@keyframes flickerout {
    0%, 5% {
        opacity: 0;
    }

    15%, 20%, 40%, 42% {
        opacity: 0.2;
    }

    48%, 57%, 65%, 75%, 77% {
        opacity: 0.5;
    }

    80%, 85%, 90%, 95% {
        opacity: 0.75;
    }

    3%, 7%, 19%, 30% {
        opacity: 0.35;
    }

    41%, 44%, 52%, 60% {
        opacity: 0.5;
    }

    70%, 76%, 78%, 84% {
        opacity: 0.65;
    }

    88%, 93%, 100% {
        opacity: 1;
    }
}

// @keyframes flickerout {
//     0%, 5% {
//         opacity: 0;
//     }

//     15%, 20%, 40%, 42% {
//         opacity: 0.2;
//     }

//     48%, 57%, 65%, 75%, 77% {
//         opacity: 0.5;
//     }

//     80%, 85%, 90%, 95% {
//         opacity: 0.75;
//     }

//     3%, 7%, 19%, 30%, 41%, 44%, 52%, 60%, 70%, 76%, 78%, 84%, 88%, 93%, 100% {
//         opacity: 1;
//     }
// }

@keyframes rotate_target {
    0% {
        transform: rotate(45deg) scale(1);
    }

    45% {
        transform: rotate(165deg) scale(1.15);
    }

    60% {
        transform: rotate(155deg) scale(1.0375);
    }

    75% {
        transform: rotate(165deg) scale(1.15);
    }

    100% {
        transform: rotate(125deg) scale(1.15);
    }
}