:root {
  --pi: 3.14159265359;
  --outer_radius: 48;
  --middle_radius: 45.5;
  --inner_radius: 43;
}

#ar_landing .rings {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
}

.rings {
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  visibility: hidden;
  transition: visibility 1.5s;

  &::after {
    content: "";
    display: block;
    position: relative;
    padding-bottom: 100%;
  }

  svg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    fill: none;
    stroke: $purple;
    
    .outer_ring {
      stroke-width: 0.5;
  
      circle {
        stroke-dasharray: 0 calc(var(--pi) * 1.5 * var(--outer_radius) * 1px);
        stroke-dashoffset: 0;
        transition: stroke-dasharray 0.35s ease-out, stroke-dashoffset 0.35s ease-out;
        transition-delay: 0.15s;
      }
  
      path {
        stroke-width: 0.5;
        stroke-dasharray: 2 2;
        stroke-dashoffset: 2;
        transition: stroke-dashoffset 0.15s ease-out;
      }
    }
    
    .inner_ring {
      stroke-width: 0.25;
    
      circle {
        stroke-dasharray: 0 calc(var(--pi) * 0.5 * var(--inner_radius) * 1px);
        stroke-dashoffset: 0;
        transition: stroke-dasharray 0.5s ease-out, stroke-dashoffset 0.5s ease-out;
      }
      
      path {
        stroke-width: 0.2;
        stroke-dasharray: 0 1;
        stroke-dashoffset: 0;
        transition: stroke-dasharray 0.25s ease-out, stroke-dashoffset 0.25s ease-out;
      }
    }
    
    .arrow_ring {
      opacity: 0;
      transition: opacity 0.5s;

      circle {
        stroke-width: 1.5;
        
        &.dashed {
          stroke-dasharray: 0 calc(var(--pi) * 0.125 * var(--middle_radius));
          transition: stroke-dasharray 0.25s ease-out;
        }
        
        &.slider {
          stroke-dasharray: 0 calc(var(--pi) * 1.8 * var(--middle_radius) * 1px);
          transition: stroke-dasharray 0.5s ease-out;
          stroke-dashoffset: 0;
        }
      }
      
      .arrow {
        fill: $white;
        stroke: none;
        opacity: 0;
        transition: opacity 0.25s;
      }

      &.show {
        .arrow {
          opacity: 1;
        }
      }

      &.hide {
        .arrow {
          opacity: 0;
        }
      }
    }
  }

  &.show {
    visibility: visible;
    transition-duration: 0s;

    .outer_ring {
      circle {
        stroke-dasharray: calc(var(--pi) * var(--outer_radius) * 1px);
        stroke-dashoffset: calc(var(--pi) * var(--outer_radius) * 1px);
        transition-delay: 0s;
      }
      
      path {
        stroke-dashoffset: 4;
        transition-delay: 0.35s;
      }
    }

    .inner_ring {
      circle {
        stroke-dasharray: calc(var(--pi) * 0.45 * var(--inner_radius) * 1px) calc(var(--pi) * 0.05 * var(--inner_radius) * 1px);
        stroke-dashoffset: calc(var(--pi) * 0.25 * var(--inner_radius) * 1px + var(--pi) * -0.025 * var(--inner_radius) * 1px);
        transition-delay: 0.6s;
      }
      
      path {
        stroke-dasharray: 1 0;
        stroke-dashoffset: 0.5;
        transition-delay: 1s;
      }
    }
    
    .arrow_ring {
      opacity: 1;

      circle {
        &.dashed {
          opacity: 1;
          stroke-dasharray: 0.25 2;
          transition-delay: 0s;
          transition-duration: 0.5s;
          animation: rotate_arrow_ring 0.75s infinite;
          animation-timing-function: linear;
        }
        
        &.slider {
          stroke-dasharray: calc(var(--pi) * 0.2 * var(--middle_radius)) calc(var(--pi) * 1.8 * var(--middle_radius) * 1px);
          animation: rotate_slider 15s infinite;
          transition-delay: 1.5s;
        }
      }
    }
  }
}

@keyframes rotate_slider {
  0% {
    stroke-dashoffset: 0;
  }

  10% {
    stroke-dashoffset: 15;
  }
  
  25% {
    stroke-dashoffset: 200;
  }

  35% {
    stroke-dashoffset: 210;
  }
  
  50% {
    stroke-dashoffset: 175;
  }
  
  60% {
    stroke-dashoffset: 185;
  }

  70% {
    stroke-dashoffset: 300;
  }

  80% {
    stroke-dashoffset: 90;
  }
  
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rotate_arrow_ring {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 2;
  }
}