/* autoprefixer grid: on */
/**
* Scale font size linearly: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
**** slope = (maxFontSize[in pixels] - minFontSize[in pixels]) / (maxWidth - minWidth)
**** yAxisIntersection = -minWidth * slope + minFontSize
**** preferredValue = yAxisIntersection[rem] + (slope * 100)[vw]
**** clamp(minFontSize (rem), preferredValue, maxFontSize (rem));
*
****** ARGS ********
* $minFontSize = min font size in pixels
* $maxFontSize = max font size in pixels
* $minScreenWidth = min screen width in pixels
* $maxScreenWidth = max screen width in pixels
**/
/**
 * There's a bug in Safari where the font-size doesn't refresh when the browser resizes.
 * The fix is to put -webkit-marquee-increment: 1vw; on any element where you're changing
 * the font size fluidly https://www.sarasoueidan.com/blog/safari-fluid-typography-bug-fix/
 */
/*
 * COLORS
 */
/*
 * ANIMATIONS
 */
/**
 * HEADER AND FOOTER MARGINS
 */
html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  -ms-content-zooming: none;
  /* Disables zooming */
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: min(clamp(0.8rem, 0.2074074074rem + 1.8518518519vw, 1rem), clamp(0.6rem, -0.5851851852rem + 3.7037037037vh, 1rem));
  -webkit-marquee-increment: 1vw;
  line-height: 1.25em;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  background: #04090f;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-touch-callout: none;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  touch-action: pan-x pan-y;
}
.cssloaded body {
  opacity: 1;
  transition: opacity 0.75s ease-in-out 0.25s;
}
body[data-section=Desktop] {
  overflow: hidden;
}
body[data-section=Desktop] #camerafeed {
  opacity: 0;
}
body[data-section=Desktop] #camerafeed.show {
  opacity: 1;
  transition: opacity 1s;
}

/* Screen Reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}

a {
  color: #fff;
  -webkit-user-drag: none;
  text-decoration: none;
}

p {
  font-size: 1.6em;
  line-height: 1.25em;
}

a:focus,
a:active,
button:focus,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
select::-moz-focus-inner,
input::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner {
  border: 0;
  outline: 0;
  line-height: 1em;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1em;
  font-weight: 500;
}

#shell {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#back_to_site {
  opacity: 0;
}
#back_to_site.show {
  z-index: 100;
  pointer-events: all;
  animation-name: flickerin;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#back_to_site.hide {
  animation-name: flickerout;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

.border_button,
.x_button {
  font-size: 1.2em;
  letter-spacing: 0.1em;
  display: inline-flex;
  align-items: center;
  height: 2.45rem;
  position: absolute;
  top: 2rem;
}

.border_button {
  padding: 0 2em;
  left: 2rem;
  color: #04090f;
  background: #fff;
}
.border_button::before, .border_button::after {
  content: "";
  position: absolute;
  left: calc(100% + 3px);
  width: 2px;
  height: calc(50% - 3px);
  background: #fff;
}
.border_button::before {
  top: 0px;
}
.border_button::after {
  bottom: 0px;
}

.x_button {
  right: 2rem;
}
.x_button .x {
  display: inline-block;
  width: 2.45rem;
  height: 2.45rem;
  border: solid 1px rgba(171, 189, 224, 0.5);
  margin-left: 1em;
  position: relative;
}
.x_button .x::before, .x_button .x::after {
  content: "";
  display: block;
  height: 1px;
  border-left: solid 0.65rem #fff;
  border-right: solid 0.65rem #fff;
  width: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.x_button .x::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

#ar footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
#ar footer p {
  font-size: 1em;
  margin: 0 1em;
  line-height: 2.75em;
  color: #666;
}

.section {
  opacity: 0;
}
.section#dossier, .section#tickets, .section.coming_soon {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.section * {
  pointer-events: none;
}
.section h1 {
  font-size: 2.2em;
  line-height: 1em;
  margin: 0 0 1em;
}
.section .bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.015, 1);
}
.section .bg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 9, 15, 0.5);
  transform: scale(1, 0.015);
}
.section.show {
  opacity: 1;
}
.section.show .bg {
  transform: scale(1, 1);
  transition: transform 0.25s ease-in;
  transition-delay: 0s;
}
.section.show .bg::before {
  transform: scale(1, 1);
  transition: transform 0.25s ease-out;
  transition-delay: 0.2s;
}
.section.hide {
  opacity: unset;
  transition: visibility 0s;
  transition-delay: 0.75s;
  visibility: hidden;
}
.section.hide iframe {
  visibility: hidden !important;
}
.section.hide .bg {
  transform: scale(0.015, 1);
  transition: transform 0.25s ease-out;
  transition-delay: 0.5s;
}
.section.hide .bg::before {
  transform: scale(1, 0.015);
  transition: transform 0.25s ease-in;
  transition-delay: 0.3s;
}
.section .info_wrapper {
  position: absolute;
  bottom: 10%;
  width: 100%;
  left: 0;
  text-align: center;
  opacity: 0;
}
.section .info_wrapper * {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.section .info_wrapper span {
  line-height: 1.5em;
  background: black;
  padding: 2px 4px;
}
.section.show .info_wrapper {
  animation-name: flickerin;
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.section.hide {
  opacity: unset;
  transition: visibility 0s;
  transition-delay: 0.5s;
  visibility: hidden;
}
.section.hide .instructions span {
  transform: translateY(-100%);
}
.section.hide .info_wrapper {
  animation-name: flickerout;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

.fullwh {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#ar_nav,
footer {
  z-index: 3;
}

#ar_zoomed .hotspot .inner {
  opacity: 0;
}
#ar_zoomed.show .hotspot .inner {
  pointer-events: all;
  animation-name: flickerin;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#ar_zoomed.show .hotspot:nth-child(2) {
  animation-delay: 0.05s;
  animation-duration: 0.65s;
}
#ar_zoomed.show .hotspot:nth-child(3) {
  animation-delay: 0.1s;
  animation-duration: 0.25s;
}
#ar_zoomed.hide {
  opacity: unset;
  transition: visibility 0s;
  transition-delay: 0.25s;
  visibility: hidden;
}
#ar_zoomed.hide .hotspot .inner {
  animation-name: flickerout;
  animation-duration: 0.35s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
#ar_zoomed.hide .hotspot:nth-child(2) {
  animation-delay: 0.25s;
  animation-duration: 0.5s;
}
#ar_zoomed.hide .hotspot:nth-child(3) {
  animation-delay: 0.1s;
  animation-duration: 0.25s;
}

#ar_find_moon,
#ar_place_moon,
#ar_calibrating {
  display: flex;
  flex-flow: column;
  align-items: center;
}
#ar_find_moon .instructions,
#ar_place_moon .instructions,
#ar_calibrating .instructions {
  text-align: center;
  position: absolute;
  max-width: 60%;
  letter-spacing: 0.3em;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  overflow: hidden;
}
#ar_find_moon .instructions span,
#ar_place_moon .instructions span,
#ar_calibrating .instructions span {
  display: block;
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.3, 0.01, 0.2, 1);
}
#ar_find_moon.show.found .instructions.point span,
#ar_place_moon.show.found .instructions.point span,
#ar_calibrating.show.found .instructions.point span {
  transform: translateY(-100%);
}
#ar_find_moon.show.found .instructions.found span,
#ar_place_moon.show.found .instructions.found span,
#ar_calibrating.show.found .instructions.found span {
  transform: translateY(0%);
}
#ar_find_moon.show.found.locked .instructions.found span,
#ar_place_moon.show.found.locked .instructions.found span,
#ar_calibrating.show.found.locked .instructions.found span {
  transform: translateY(-100%);
}
#ar_find_moon.show .instructions:first-child span,
#ar_place_moon.show .instructions:first-child span,
#ar_calibrating.show .instructions:first-child span {
  transform: translateY(0%);
}
#ar_find_moon.hide,
#ar_place_moon.hide,
#ar_calibrating.hide {
  opacity: unset;
  transition: visibility 0s;
  transition-delay: 0.25s;
  visibility: hidden;
}
#ar_find_moon.hide .instructions span,
#ar_place_moon.hide .instructions span,
#ar_calibrating.hide .instructions span {
  transform: translateY(-100%) !important;
}

#ar_calibrating .instructions {
  top: 75%;
  max-width: 90%;
}
#ar_calibrating .instructions p {
  margin-top: 0;
}
#ar_calibrating .instructions span::before {
  content: "[";
  margin-right: 0.75em;
}
#ar_calibrating .instructions span::after {
  content: "]";
  margin-left: 0.75em;
}
#ar_calibrating .instructions .calibration_progress {
  display: block;
  width: 100%;
  height: 0.65em;
  background: #666;
  margin: 3em auto 0;
  position: relative;
}
#ar_calibrating .instructions .calibration_progress div {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #abbde0;
  transform: scaleX(0);
  transform-origin: 0 0;
}

#ar_find_moon #moon_target {
  --target-size: 10em;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate3d(-50%, -50%, 1px);
}
#ar_find_moon #moon_target .target_box {
  margin: 0 auto;
}
#ar_find_moon #moon_target.found .target_box {
  transform: scale(0.75);
}
#ar_find_moon #moon_target.found .target_box .inner {
  opacity: 1;
  transition-delay: 0.25s;
  animation-name: rotate_target;
  animation-duration: 1s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.target_box {
  width: var(--target-size);
  height: var(--target-size);
  position: relative;
  transform: scale(1);
  transition: transform 0.75s cubic-bezier(0.3, 0.01, 0.2, 1);
}
.target_box.test {
  --target-size: 5em;
}
.target_box > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.target_box > div.inner {
  width: 75%;
  height: 75%;
  top: 12.5%;
  left: 12.5%;
  opacity: 0;
  transform: rotate(45deg) scale(1);
  transition: opacity 0.25s;
}
.target_box > div.inner > div::after {
  width: calc(var(--target-size) * 0.75);
  height: calc(var(--target-size) * 0.75);
  border-width: 2px;
}
.target_box > div > div {
  position: absolute;
  width: 45%;
  height: 45%;
  overflow: hidden;
}
.target_box > div > div::after {
  content: "";
  display: block;
  width: var(--target-size);
  height: var(--target-size);
  position: absolute;
  border: solid 1px #abbde0;
  border-radius: 100%;
}
.target_box > div > div.ul {
  top: 0;
  left: 0;
}
.target_box > div > div.ul::after {
  top: 0;
  left: 0;
}
.target_box > div > div.ur {
  top: 0;
  right: 0;
}
.target_box > div > div.ur::after {
  top: 0;
  right: 0;
}
.target_box > div > div.bl {
  bottom: 0;
  left: 0;
}
.target_box > div > div.bl::after {
  bottom: 0;
  left: 0;
}
.target_box > div > div.br {
  bottom: 0;
  right: 0;
}
.target_box > div > div.br::after {
  bottom: 0;
  right: 0;
}

.hotspot {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}
.hotspot.visited {
  opacity: 0.75;
}
.hotspot.visited .inner {
  border-color: #666;
}
.hotspot .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 6em;
  height: 6em;
  border: solid 2px #abbde0;
  left: 0px;
  top: 0px;
  transform: translate(-50%, -50%);
}
.hotspot .inner::after {
  content: "+";
  display: block;
  width: 70%;
  height: 70%;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 15%;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_sizer {
  display: block;
  width: 100%;
  position: relative;
}
.image_sizer::before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
  background: #3f3f3f;
}

.dg.ac {
  z-index: 100;
}

.get_tickets_button {
  font-size: 2.5em;
  letter-spacing: 0.15em;
  border: solid 2px #abbde0;
  line-height: 2em;
  padding: 0 2em;
  margin-top: 1em;
  z-index: 1;
}

#ar_nav {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#ar_nav .close_button {
  opacity: 0;
}
#ar_nav .close_button.show {
  pointer-events: all;
  animation-name: flickerin;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#ar_nav .close_button.show span {
  pointer-events: none;
}
#ar_nav .close_button.hide {
  animation-name: flickerout;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

#tmp_moon {
  opacity: 0;
  position: absolute;
  left: 50%;
  transition: opacity 0.5s;
}
#ar_landing {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
#ar_landing .tt {
  position: relative;
  max-width: 95%;
  margin: 3em auto 1.5em;
}
#ar_landing .rings {
  opacity: 0.5;
  margin-bottom: -2em;
}
#ar_landing .bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1, 1);
  display: none;
}
#ar_landing.show {
  pointer-events: all;
  opacity: 1;
}
#ar_landing.show.hide {
  pointer-events: none;
}
#ar_landing.show.hide #tmp_moon {
  opacity: 0;
}
#ar_landing.hide {
  opacity: unset;
  transition: visibility 0s;
  transition-delay: 0.75s;
  visibility: hidden;
}
#ar_landing.hide .bg {
  transform: scale(0.015, 1);
  transition: transform 0.25s ease-out;
  transition-delay: 0.5s;
}
#ar_landing.hide .bg::before {
  transform: scale(1, 0.015);
  transition: transform 0.25s ease-in;
  transition-delay: 0.3s;
}
#ar_landing.hide h1,
#ar_landing.hide #version,
#ar_landing.hide a {
  animation-name: flickerout;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
#ar_landing h1 {
  font-size: 1.3rem;
  font-weight: 300;
  margin: 0 0 3em;
  padding-bottom: 3em;
  text-align: center;
  position: relative;
  line-height: 1.45em;
  letter-spacing: 0.25em;
}
#ar_landing h1::after {
  content: "...";
  font-weight: 500;
  font-size: 2rem;
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  line-height: 1em;
  color: #abbde0;
}
#ar_landing #version {
  font-size: 1.849em;
  color: #abbde0;
  margin: 0;
  position: relative;
}
#ar_landing a {
  font-size: 1.8em;
  line-height: 2em;
  font-weight: 500;
  color: #abbde0;
  position: relative;
  letter-spacing: 0.1em;
}
#ar_landing a::before {
  content: "[";
  margin-right: 0.5em;
}
#ar_landing a::after {
  content: "]";
  margin-left: 0.5em;
}

:root {
  --pi: 3.14159265359;
  --outer_radius: 48;
  --middle_radius: 45.5;
  --inner_radius: 43;
}

#ar_landing .rings {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
}

.rings {
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  transition: visibility 1.5s;
}
.rings::after {
  content: "";
  display: block;
  position: relative;
  padding-bottom: 100%;
}
.rings svg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: none;
  stroke: #abbde0;
}
.rings svg .outer_ring {
  stroke-width: 0.5;
}
.rings svg .outer_ring circle {
  stroke-dasharray: 0 calc(var(--pi) * 1.5 * var(--outer_radius) * 1px);
  stroke-dashoffset: 0;
  transition: stroke-dasharray 0.35s ease-out, stroke-dashoffset 0.35s ease-out;
  transition-delay: 0.15s;
}
.rings svg .outer_ring path {
  stroke-width: 0.5;
  stroke-dasharray: 2 2;
  stroke-dashoffset: 2;
  transition: stroke-dashoffset 0.15s ease-out;
}
.rings svg .inner_ring {
  stroke-width: 0.25;
}
.rings svg .inner_ring circle {
  stroke-dasharray: 0 calc(var(--pi) * 0.5 * var(--inner_radius) * 1px);
  stroke-dashoffset: 0;
  transition: stroke-dasharray 0.5s ease-out, stroke-dashoffset 0.5s ease-out;
}
.rings svg .inner_ring path {
  stroke-width: 0.2;
  stroke-dasharray: 0 1;
  stroke-dashoffset: 0;
  transition: stroke-dasharray 0.25s ease-out, stroke-dashoffset 0.25s ease-out;
}
.rings svg .arrow_ring {
  opacity: 0;
  transition: opacity 0.5s;
}
.rings svg .arrow_ring circle {
  stroke-width: 1.5;
}
.rings svg .arrow_ring circle.dashed {
  stroke-dasharray: 0 calc(var(--pi) * 0.125 * var(--middle_radius));
  transition: stroke-dasharray 0.25s ease-out;
}
.rings svg .arrow_ring circle.slider {
  stroke-dasharray: 0 calc(var(--pi) * 1.8 * var(--middle_radius) * 1px);
  transition: stroke-dasharray 0.5s ease-out;
  stroke-dashoffset: 0;
}
.rings svg .arrow_ring .arrow {
  fill: #fff;
  stroke: none;
  opacity: 0;
  transition: opacity 0.25s;
}
.rings svg .arrow_ring.show .arrow {
  opacity: 1;
}
.rings svg .arrow_ring.hide .arrow {
  opacity: 0;
}
.rings.show {
  visibility: visible;
  transition-duration: 0s;
}
.rings.show .outer_ring circle {
  stroke-dasharray: calc(var(--pi) * var(--outer_radius) * 1px);
  stroke-dashoffset: calc(var(--pi) * var(--outer_radius) * 1px);
  transition-delay: 0s;
}
.rings.show .outer_ring path {
  stroke-dashoffset: 4;
  transition-delay: 0.35s;
}
.rings.show .inner_ring circle {
  stroke-dasharray: calc(var(--pi) * 0.45 * var(--inner_radius) * 1px) calc(var(--pi) * 0.05 * var(--inner_radius) * 1px);
  stroke-dashoffset: calc(var(--pi) * 0.25 * var(--inner_radius) * 1px + var(--pi) * -0.025 * var(--inner_radius) * 1px);
  transition-delay: 0.6s;
}
.rings.show .inner_ring path {
  stroke-dasharray: 1 0;
  stroke-dashoffset: 0.5;
  transition-delay: 1s;
}
.rings.show .arrow_ring {
  opacity: 1;
}
.rings.show .arrow_ring circle.dashed {
  opacity: 1;
  stroke-dasharray: 0.25 2;
  transition-delay: 0s;
  transition-duration: 0.5s;
  animation: rotate_arrow_ring 0.75s infinite;
  animation-timing-function: linear;
}
.rings.show .arrow_ring circle.slider {
  stroke-dasharray: calc(var(--pi) * 0.2 * var(--middle_radius)) calc(var(--pi) * 1.8 * var(--middle_radius) * 1px);
  animation: rotate_slider 15s infinite;
  transition-delay: 1.5s;
}

@keyframes rotate_slider {
  0% {
    stroke-dashoffset: 0;
  }
  10% {
    stroke-dashoffset: 15;
  }
  25% {
    stroke-dashoffset: 200;
  }
  35% {
    stroke-dashoffset: 210;
  }
  50% {
    stroke-dashoffset: 175;
  }
  60% {
    stroke-dashoffset: 185;
  }
  70% {
    stroke-dashoffset: 300;
  }
  80% {
    stroke-dashoffset: 90;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes rotate_arrow_ring {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 2;
  }
}
#dossier ul li,
#dossier h1,
#bagel ul li,
#bagel h1,
#cloud ul li,
#cloud h1 {
  opacity: 0;
}
#dossier.show ul li,
#dossier.show h1,
#bagel.show ul li,
#bagel.show h1,
#cloud.show ul li,
#cloud.show h1 {
  animation-name: flickerin;
  animation-duration: 0.2s;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#dossier.show h1,
#bagel.show h1,
#cloud.show h1 {
  animation-duration: 0.25s;
  animation-delay: 0.45s;
}
#dossier.show ul li:nth-child(1),
#bagel.show ul li:nth-child(1),
#cloud.show ul li:nth-child(1) {
  animation-duration: 0.2s;
  animation-delay: 0.45s;
}
#dossier.show ul li:nth-child(2),
#bagel.show ul li:nth-child(2),
#cloud.show ul li:nth-child(2) {
  animation-duration: 1s;
  animation-delay: 0.35s;
}
#dossier.show ul li:nth-child(3),
#bagel.show ul li:nth-child(3),
#cloud.show ul li:nth-child(3) {
  animation-duration: 0.3s;
  animation-delay: 0.4s;
}
#dossier.show ul li:nth-child(4),
#bagel.show ul li:nth-child(4),
#cloud.show ul li:nth-child(4) {
  animation-duration: 0.15s;
  animation-delay: 0.4s;
}
#dossier.show ul li:nth-child(5),
#bagel.show ul li:nth-child(5),
#cloud.show ul li:nth-child(5) {
  animation-duration: 0.4s;
  animation-delay: 0.4s;
}
#dossier.show ul li:nth-child(6),
#bagel.show ul li:nth-child(6),
#cloud.show ul li:nth-child(6) {
  animation-duration: 0.25s;
  animation-delay: 0.4s;
}
#dossier.hide ul li,
#dossier.hide h1,
#bagel.hide ul li,
#bagel.hide h1,
#cloud.hide ul li,
#cloud.hide h1 {
  animation-name: flickerout;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
#dossier.hide ul li:nth-child(1),
#bagel.hide ul li:nth-child(1),
#cloud.hide ul li:nth-child(1) {
  animation-duration: 0.2s;
  animation-delay: 0.15s;
}
#dossier.hide ul li:nth-child(2),
#bagel.hide ul li:nth-child(2),
#cloud.hide ul li:nth-child(2) {
  animation-duration: 0.4s;
  animation-delay: 0s;
}
#dossier.hide ul li:nth-child(3),
#bagel.hide ul li:nth-child(3),
#cloud.hide ul li:nth-child(3) {
  animation-duration: 0.25s;
  animation-delay: 0.075s;
}
#dossier.hide ul li:nth-child(4),
#bagel.hide ul li:nth-child(4),
#cloud.hide ul li:nth-child(4) {
  animation-duration: 0.25s;
  animation-delay: 0.05s;
}
#dossier.hide ul li:nth-child(5),
#bagel.hide ul li:nth-child(5),
#cloud.hide ul li:nth-child(5) {
  animation-duration: 0.75s;
  animation-delay: 0s;
}
#dossier.hide ul li:nth-child(6),
#bagel.hide ul li:nth-child(6),
#cloud.hide ul li:nth-child(6) {
  animation-duration: 0.25s;
  animation-delay: 0.05s;
}
#dossier a,
#bagel a,
#cloud a {
  pointer-events: none;
}
#dossier.show:not(.hide) .files,
#dossier.show:not(.hide) a,
#bagel.show:not(.hide) .files,
#bagel.show:not(.hide) a,
#cloud.show:not(.hide) .files,
#cloud.show:not(.hide) a {
  pointer-events: all;
}
#dossier .files,
#bagel .files,
#cloud .files {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  pointer-events: none;
}
#dossier .files li,
#bagel .files li,
#cloud .files li {
  flex-basis: 50%;
  padding: 0.5em;
  position: relative;
}
#dossier .files li.nothumb p,
#bagel .files li.nothumb p,
#cloud .files li.nothumb p {
  color: #c00000;
}
#dossier .files li img,
#bagel .files li img,
#cloud .files li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#dossier .files li p,
#bagel .files li p,
#cloud .files li p {
  text-align: center;
  margin: 0.25em 0 1em;
  font-size: 1.4em;
  line-height: 1.5em;
}
#dossier #youtube_modal,
#bagel #youtube_modal,
#cloud #youtube_modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
#dossier #youtube_modal.show,
#bagel #youtube_modal.show,
#cloud #youtube_modal.show {
  opacity: 1;
  pointer-events: all;
}
#dossier #youtube_modal.show .vid_wrapper iframe,
#bagel #youtube_modal.show .vid_wrapper iframe,
#cloud #youtube_modal.show .vid_wrapper iframe {
  display: block;
  pointer-events: all;
}
#dossier #youtube_modal .vid_wrapper,
#bagel #youtube_modal .vid_wrapper,
#cloud #youtube_modal .vid_wrapper {
  position: relative;
  width: 90%;
}
#dossier #youtube_modal .vid_wrapper::before,
#bagel #youtube_modal .vid_wrapper::before,
#cloud #youtube_modal .vid_wrapper::before {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
#dossier #youtube_modal .vid_wrapper iframe,
#bagel #youtube_modal .vid_wrapper iframe,
#cloud #youtube_modal .vid_wrapper iframe {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#dossier {
  z-index: 2;
}
#dossier.show:not(.hide) .dossier_wrapper {
  pointer-events: all;
}
#dossier h1 {
  margin-top: 5em;
}
#dossier .files {
  padding-bottom: 2em;
}
#dossier .dossier_wrapper {
  display: flex;
  align-items: center;
  flex-flow: column;
  flex-grow: 100;
  overflow: auto;
}

html body .e-widget-preloader, html body .e-widget-wrapper {
  box-shadow: none !important;
}

.contest {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 100%;
  font-weight: 300;
}
.contest .e-widget-wrapper {
  height: 100%;
}
.contest .e-widget-wrapper iframe {
  height: 100% !important;
}
.contest .e-widget-preloader,
.contest iframe.e-embed-frame {
  background: transparent;
  margin: 0;
}
.contest.show a {
  pointer-events: all;
}
.contest.show iframe {
  pointer-events: all;
}
.contest .big {
  font-size: 2.5em;
  display: block;
  line-height: 1.5em;
}
.contest .top_wrapper {
  flex-grow: 10;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  max-width: 85%;
  margin-top: 1em;
}
.contest .top_wrapper a {
  font-size: 2.5em;
  margin-top: 1em;
  background: #abbde0;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 2.5em;
  width: 90%;
}
.contest .top_wrapper p {
  line-height: 1.5em;
}
.contest h1 {
  margin-bottom: 0em;
  margin-top: 3.5em;
  font-size: 3em;
  line-height: 1.25em;
  font-weight: 300;
}
.contest h1,
.contest p {
  letter-spacing: 0.15em;
  position: relative;
}
.contest .get_tickets_button {
  font-size: 1.75em;
  margin: 0 auto 2em;
  color: #fff;
}

#debris {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
#debris h1 {
  color: #abbde0;
  font-size: 1.1em;
  max-width: 75%;
  position: absolute;
  top: 15%;
}
#debris p {
  font-size: 1.6em;
  line-height: 1.25em;
}

#tickets {
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
#tickets.show a {
  pointer-events: all;
}
#tickets .top_wrapper {
  flex-grow: 10;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
}
#tickets .top_wrapper p {
  line-height: 1.5em;
}
#tickets .top_wrapper a {
  background: transparent;
}
#tickets h1,
#tickets p {
  letter-spacing: 0.15em;
  position: relative;
}
#tickets .theaters {
  margin: 0;
  padding-bottom: 2em;
}

#size_comparison .info_wrapper a.external_link {
  color: #abbde0;
  text-decoration: underline;
  pointer-events: all;
}
#size_comparison #moon_label,
#size_comparison #sun_label {
  position: absolute;
  font-size: 1.1em;
  background: rgba(4, 9, 15, 0.5);
  border-top-width: 5px;
  margin: 0;
  opacity: 0;
}
#size_comparison #moon_label i,
#size_comparison #sun_label i {
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: 100%;
  border-bottom: solid 2px #abbde0;
  height: 0px;
  font-style: normal;
}
#size_comparison #moon_label i b,
#size_comparison #sun_label i b {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  border-right: solid 2px #abbde0;
  font-weight: 500;
}
#size_comparison #moon_label i b span,
#size_comparison #sun_label i b span {
  display: block;
  position: absolute;
  font-size: 2em;
  top: 50%;
  left: calc(100% + 0.5em);
  transform: translateY(-50%);
}
#size_comparison.show #moon_label,
#size_comparison.show #sun_label {
  pointer-events: all;
  animation-name: flickerin;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#size_comparison.hide #moon_label,
#size_comparison.hide #sun_label {
  animation-name: flickerout;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

#loadingContainer,
#requestingCameraPermissions,
.loading-error-instructions,
.loading-error-instructions > li:before,
.highlight,
.start-ar-button,
.open-header-unknown,
.desktop-home-link,
.desktop-message,
.desktop-hint,
.copy-link-btn,
.error-text-header,
.prompt-box-8w {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

#qrcode {
  border-radius: 0px;
}

#loadBackground,
#requestingCameraPermissions,
#runtimeErrorContainer,
#cameraPermissionsErrorApple, #microphonePermissionsErrorApple,
#cameraPermissionsErrorAndroid, #microphonePermissionsErrorAndroid,
#linkOutViewAndroid, #copyLinkViewAndroid,
#deviceMotionErrorApple,
.permission-error,
#cameraSelectionWorldTrackingError {
  background: #04090f;
}

#almostthereContainer {
  display: none;
}

body[data-section=Desktop] #almostthereContainer {
  display: flex;
  background: transparent;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
}
body[data-section=Desktop] #almostthereContainer.show {
  opacity: 1;
  transition: opacity 2s 0.75s;
}
body[data-section=Desktop] #almostthereContainer .poweredby-img.desktop {
  width: 150px;
}
body[data-section=Desktop] #almostthereContainer .tt_wrapper_wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-grow: 100;
}
body[data-section=Desktop] #almostthereContainer .tt_wrapper {
  margin-top: 10em;
  margin-bottom: 4em;
}
body[data-section=Desktop] #almostthereContainer .tt_wrapper h1 {
  font-size: 2.3em;
  letter-spacing: 0.2em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0.2em;
  font-weight: 300;
}
body[data-section=Desktop] #almostthereContainer #qrcode,
body[data-section=Desktop] #almostthereContainer #qrcode svg {
  width: 175px;
  height: 175px;
}
body[data-section=Desktop] #almostthereContainer .desktop-hint {
  line-height: 1.25em;
}
body[data-section=Desktop] #almostthereContainer .desktop-hint br {
  display: none;
}
body[data-section=Desktop] #almostthereContainer .desktop-hint .desktop-home-link {
  background: transparent;
  color: #fff;
  padding: 0;
  display: block;
}
body[data-section=Desktop] #almostthereContainer .error-msg-device {
  flex-grow: 100;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
body[data-section=Desktop] #almostthereContainer .error-text-outer-container {
  margin-bottom: 9em;
}
body[data-section=Desktop] #almostthereContainer .error-text-container {
  margin: 0;
  font-size: 1em;
  margin-bottom: 8em;
}
body[data-section=Desktop] #almostthereContainer .error-text-container .desktop-message {
  font-size: 2em;
  line-height: 1.25em;
  width: 15em;
}
body[data-section=Desktop] #almostthereContainer .error-text-container .desktop-hint {
  font-size: 1em;
  line-height: 1.25em;
}
body[data-section=Desktop] #almostthereContainer .error-text-container .desktop-hint > span {
  font-size: 1.5em !important;
  line-height: 1.25em !important;
}

.desktop-home-link {
  background: #04090f;
  border-radius: 0px;
  color: #abbde0;
}

body:not(.xrextras-old-style) .prompt-box-8w {
  background: #04090f;
  border-radius: 0;
}
body:not(.xrextras-old-style) .prompt-button-8w {
  background: #04090f;
  border: solid 2px #abbde0;
  border-radius: 0px;
  text-transform: uppercase;
}
body:not(.xrextras-old-style) .button-primary-8w {
  background: #abbde0;
  color: #04090f;
}

#loadImage {
  display: none;
}

#loadingContainer .loader {
  position: absolute;
  width: 10em;
  height: 10em;
  background: transparent;
  top: calc(50% - 5em);
  left: calc(50% - 5em);
  border: solid 1px transparent;
  border-top: solid 1px #abbde0;
  border-left: solid 1px #abbde0;
  border-radius: 100%;
  transform: rotate(0deg);
  animation-name: loading;
  animation-duration: 6.2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.3, 0.01, 0.2, 1);
  animation-direction: reverse;
  opacity: 0.75;
}
#loadingContainer .loader::before, #loadingContainer .loader::after {
  content: "";
  display: block;
  position: absolute;
  border: solid 1px transparent;
  border-top: solid 1px #abbde0;
  border-left: solid 1px #abbde0;
  border-radius: 100%;
  box-sizing: border-box;
  transform: rotate(0deg);
  animation-name: loading;
  animation-timing-function: cubic-bezier(0.3, 0.01, 0.2, 1);
}
#loadingContainer .loader::before {
  width: calc(100% - (1em - 2px));
  height: calc(100% - (1em - 2px));
  left: calc(0.5em - 1px);
  top: calc(0.5em - 1px);
  animation-duration: 5.3s;
  animation-iteration-count: infinite;
}
#loadingContainer .loader::after {
  width: calc(100% - (2em - 2px));
  height: calc(100% - (2em - 2px));
  left: calc(1em - 1px);
  top: calc(1em - 1px);
  animation-duration: 5.73s;
  animation-iteration-count: infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-ms-viewport {
  width: device-width;
}
@keyframes flickerin {
  0%, 5% {
    opacity: 0;
  }
  15%, 20%, 40%, 42% {
    opacity: 0.2;
  }
  48%, 57%, 65%, 75%, 77% {
    opacity: 0.5;
  }
  80%, 85%, 90%, 95% {
    opacity: 0.75;
  }
  3%, 7%, 19%, 30% {
    opacity: 0.35;
  }
  41%, 44%, 52%, 60% {
    opacity: 0.5;
  }
  70%, 76%, 78%, 84% {
    opacity: 0.65;
  }
  88%, 93%, 100% {
    opacity: 1;
  }
}
@keyframes flickerout {
  0%, 5% {
    opacity: 0;
  }
  15%, 20%, 40%, 42% {
    opacity: 0.2;
  }
  48%, 57%, 65%, 75%, 77% {
    opacity: 0.5;
  }
  80%, 85%, 90%, 95% {
    opacity: 0.75;
  }
  3%, 7%, 19%, 30% {
    opacity: 0.35;
  }
  41%, 44%, 52%, 60% {
    opacity: 0.5;
  }
  70%, 76%, 78%, 84% {
    opacity: 0.65;
  }
  88%, 93%, 100% {
    opacity: 1;
  }
}
@keyframes rotate_target {
  0% {
    transform: rotate(45deg) scale(1);
  }
  45% {
    transform: rotate(165deg) scale(1.15);
  }
  60% {
    transform: rotate(155deg) scale(1.0375);
  }
  75% {
    transform: rotate(165deg) scale(1.15);
  }
  100% {
    transform: rotate(125deg) scale(1.15);
  }
}