#loadingContainer,
#requestingCameraPermissions,
.loading-error-instructions,
.loading-error-instructions>li:before,
.highlight,
.start-ar-button,
.open-header-unknown,
.desktop-home-link,
.desktop-message,
.desktop-hint,
.copy-link-btn,
.error-text-header,
.prompt-box-8w {
  font-family: $regular_font;
  font-weight: $normal;
}

#qrcode {
  border-radius: 0px;
}

#loadBackground,
#requestingCameraPermissions,
#runtimeErrorContainer,
#cameraPermissionsErrorApple, #microphonePermissionsErrorApple,
#cameraPermissionsErrorAndroid, #microphonePermissionsErrorAndroid,
#linkOutViewAndroid, #copyLinkViewAndroid,
#deviceMotionErrorApple,
.permission-error,
#cameraSelectionWorldTrackingError {
  background: $black;
}

#almostthereContainer {
  display: none;
}

body[data-section="Desktop"] {
  #almostthereContainer {
    display: flex;
    background: transparent;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    opacity: 0;

    &.show {
      opacity: 1;
      transition: opacity 2s 0.75s;
    }

    .poweredby-img.desktop {
      width: 150px;
    }

    .tt_wrapper_wrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex-grow: 100;
    }

    .tt_wrapper {
      margin-top: 10em;
      margin-bottom: 4em;

      h1 {
        font-size: 2.3em;
        letter-spacing: .2em;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0.2em;
        font-weight: 300;
      }
    }

    #qrcode,
    #qrcode svg {
      width: 175px;
      height: 175px;
    }

    .desktop-hint {
      line-height: 1.25em;

      br {
        display: none;
      }

      .desktop-home-link {
        background: transparent;
        color: $white;
        padding: 0;
        display: block;
      }
    }

    .error-msg-device {
      flex-grow: 100;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .error-text-outer-container {
      margin-bottom: 9em;
    }

    .error-text-container {
      margin: 0;
      font-size: 1em;
      margin-bottom: 8em;

      .desktop-message {
        font-size: 2em;
        line-height: 1.25em;
        width: 15em;
      }

      .desktop-hint {
        font-size: 1em;
        line-height: 1.25em;

        &>span {
          font-size: 1.5em !important;
          line-height: 1.25em !important;
        }
      }
    }
  }
}



.desktop-home-link {
  background: $black;
  border-radius: 0px;
  color: $purple;
}

body:not(.xrextras-old-style) {
  .prompt-box-8w {
    background: $black;
    border-radius: 0;
  }

  .prompt-button-8w {
    background: $black;
    border: solid 2px $purple;
    border-radius: 0px;
    text-transform: uppercase;
  }

  .button-primary-8w {
    background: $purple;
    color: $black;
  }
}

#loadImage {
  display: none;
}

#loadingContainer .loader {
  position: absolute;
  width: 10em;
  height: 10em;
  background: transparent;
  top: calc(50% - 5em);
  left: calc(50% - 5em);
  border: solid 1px transparent;
  border-top: solid 1px $purple;
  border-left: solid 1px $purple;
  border-radius: 100%;
  transform: rotate(0deg);
  animation-name: loading;
  animation-duration: 6.2s;
  animation-iteration-count: infinite;
  animation-timing-function: $ease_in_out;
  animation-direction: reverse;
  opacity: 0.75;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    border: solid 1px transparent;
    border-top: solid 1px $purple;
    border-left: solid 1px $purple;
    border-radius: 100%;
    box-sizing: border-box;
    transform: rotate(0deg);
    animation-name: loading;
    animation-timing-function: $ease_in_out;
  }

  &::before {
    width: calc(100% - (1em - 2px));
    height: calc(100% - (1em - 2px));
    left: calc(0.5em - 1px);
    top: calc(0.5em - 1px);
    animation-duration: 5.3s;
    animation-iteration-count: infinite;
  }

  &::after {
    width: calc(100% - (2em - 2px));
    height: calc(100% - (2em - 2px));
    left: calc(1em - 1px);
    top: calc(1em - 1px);
    animation-duration: 5.73s;
    animation-iteration-count: infinite;
  }
  // div {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: $purple;
  //   animation: loading 1s steps(1) infinite;
    
  //   &::before,
  //   &::after {
  //     content: "";
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     background: $purple;
  //     animation: loading2 1s steps(1) infinite;
  //   }
      
  //   &::after {
  //     animation-delay: -0.5s;
  //   }
 
  //   &:last-child {
  //     animation-delay: -0.5s;
  //   }
  // };
}


@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// #loadingContainer .loader {
//   position: absolute;
//   width: 5px;
//   height: 5px;
//   background: $purple;
//   top: calc(50% - 3px);
//   left: calc(50% - 2px);
  
//   div {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: $purple;
//     animation: loading 1s steps(1) infinite;
    
//     &::before,
//     &::after {
//       content: "";
//       display: block;
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       background: $purple;
//       animation: loading2 1s steps(1) infinite;
//     }
      
//     &::after {
//       animation-delay: -0.5s;
//     }
 
//     &:last-child {
//       animation-delay: -0.5s;
//     }
//   };
// }

// @keyframes loading {
//   0% {
//     top: -200%;
//     left: 0%;
//   }
  
//   12.5% {
//     top: -200%;
//     left: 200%;
//   }
  
//   25% {
//     top: 0%;
//     left: 200%;
//   }
  
//   37.5% {
//     top: 200%;
//     left: 200%;
//   }
  
//   50% {
//     top: 200%;
//     left: 0%;
//   }
  
//   62.5% {
//     top: 200%;
//     left: -200%;
//   }
  
//   75% {
//     top: 0%;
//     left: -200%;
//   }
  
//   87.5% {
//     top: -200%;
//     left: -200%;
//   }
  
//   100% {
//     top: -200%;
//     left: 0%;
//   }
// }

// @keyframes loading2 {
//   0% {
//     top: -100%;
//     left: 0%;
//   }
  
//   12.5% {
//     top: -100%;
//     left: 100%;
//   }
  
//   25% {
//     top: 0%;
//     left: 100%;
//   }
  
//   37.5% {
//     top: 100%;
//     left: 100%;
//   }
  
//   50% {
//     top: 100%;
//     left: 0%;
//   }
  
//   62.5% {
//     top: 100%;
//     left: -100%;
//   }
  
//   75% {
//     top: 0%;
//     left: -100%;
//   }
  
//   87.5% {
//     top: -100%;
//     left: -100%;
//   }
  
//   100% {
//     top: -100%;
//     left: 0%;
//   }
// }