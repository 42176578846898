#ar_nav {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .close_button {
    opacity: 0;

    &.show {
        pointer-events: all;
        animation-name: flickerin;
        animation-duration: 0.25s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        span {
            pointer-events: none;
        }
    }

    &.hide {
        animation-name: flickerout;
        animation-duration: 0.25s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: reverse;
        animation-fill-mode: forwards;
    }
  }
}